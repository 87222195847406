import { createEntityAdapter, createSlice, PayloadAction } from '@reduxjs/toolkit'
import persistReducer from 'redux-persist/es/persistReducer'
import { StoreState } from '~/types/store'
import {
  LeaveTypeState,
  LtSettingState,
  LtPolicyState,
  LtPolicyExtraState,
  LtProrationMState,
  LpEntitlementState,
  LpLeaveGroupState,
  SysLeaveTypeState,
  SysLtFieldState,
  LeaveApplyFormInfoState,
  LeaveEntitlementState,
  LeaveEntitlementViewState,
  LeavePeriodYearState,
  LeavePeriodState,
  LeaveRecordViewState,
  LeaveRecordState,
  LeaveRecordDtlState,
  LeaveRecordAttachmentState,
  LeaveGrantState,
  LeEarnedState,
  EntitledLeaveTypeState,
  PendingLeaveResultState,
  NextEntitlementResultState,
  LeaveGrantBulkState,
  LeaveGrantBulkVerifyState,
  LeavePendingApprovalViewState,
  LveCalendarConfigState,
  LeaveRecordApprovalHistoryState,
  LeaveBuddyState,
  LeaveBuddyEmState,
  GoogleCalendarState,
  GoogleCalendarConnectionState
} from './types'
import { leavePersistConfig, LeaveRootState } from './types/state'

const leaveTypesAdapter = createEntityAdapter<LeaveTypeState>()
const leaveTypesViewAdapter = createEntityAdapter<LeaveTypeState>()
const ltSettingsAdapter = createEntityAdapter<LtSettingState>({
  selectId: model => model.sysSettingCode
})

const ltPoliciesAdapter = createEntityAdapter<LtPolicyState>()
const ltPoliciesExtraAdapter = createEntityAdapter<LtPolicyExtraState>()
const ltProrationMAdapter = createEntityAdapter<LtProrationMState>()
const lpEntitlementsAdapter = createEntityAdapter<LpEntitlementState>()
const lpLeaveGroupsAdapter = createEntityAdapter<LpLeaveGroupState>()

const sysLeaveTypesAdapter = createEntityAdapter<SysLeaveTypeState>({
  selectId: model => model.code
})

const sysLtFieldsAdapter = createEntityAdapter<SysLtFieldState>({
  selectId: model => model.fieldName
})

const lveCalendarConfigAdapter = createEntityAdapter<LveCalendarConfigState>()

const leavePeriodYearAdapter = createEntityAdapter<LeavePeriodYearState>({ selectId: model => model.periodYear })
const leavePeriodAdapter = createEntityAdapter<LeavePeriodState>({ selectId: model => model.periodCode })
const leaveEntitlementViewAdapter = createEntityAdapter<LeaveEntitlementViewState>({ selectId: model => model.viewId })
const leaveEntitlementAdapter = createEntityAdapter<LeaveEntitlementState>()
const leEarnedAdapter = createEntityAdapter<LeEarnedState>()
const leaveRecordViewAdapter = createEntityAdapter<LeaveRecordViewState>({ selectId: model => model.viewId })
const leaveRecordAdapter = createEntityAdapter<LeaveRecordState>()
const leaveRecordDtlsAdapter = createEntityAdapter<LeaveRecordDtlState>()
const leaveApplyFormInfoAdapter = createEntityAdapter<LeaveApplyFormInfoState>({ selectId: model => model.code })
const leaveRecordAttachmentAdapter = createEntityAdapter<LeaveRecordAttachmentState>()
const leaveRecordApprovalHistoriesAdapter = createEntityAdapter<LeaveRecordApprovalHistoryState>()
const leaveGrantAdapter = createEntityAdapter<LeaveGrantState>()
const entitledLeaveTypeAdapter = createEntityAdapter<EntitledLeaveTypeState>({ selectId: model => model.leaveTypeId })
const leavePendingApprovalsViewAdapter = createEntityAdapter<LeavePendingApprovalViewState>({
  selectId: model => model.viewId
})
const leaveBuddiesAdapter = createEntityAdapter<LeaveBuddyState>()
const leaveBuddyEmAdapter = createEntityAdapter<LeaveBuddyEmState>()

const googleCalendarsAdapter = createEntityAdapter<GoogleCalendarState>()
const googleCalendarConnectionsAdapter = createEntityAdapter<GoogleCalendarConnectionState>()

const leaveTypesInitialState = leaveTypesAdapter.getInitialState()
const leaveTypesViewInitialState = leaveTypesViewAdapter.getInitialState()
const ltSettingsInitialState = ltSettingsAdapter.getInitialState()
const ltPoliciesInitialState = ltPoliciesAdapter.getInitialState()
const ltPoliciesExtraInitialState = ltPoliciesExtraAdapter.getInitialState()
const ltProrationMInitialState = ltProrationMAdapter.getInitialState()
const lpEntitlementsInitialState = lpEntitlementsAdapter.getInitialState()
const lpLeaveGroupsInitialState = lpLeaveGroupsAdapter.getInitialState()
const sysLeaveTypesInitialState = sysLeaveTypesAdapter.getInitialState()
const sysLtFieldsInitialState = sysLtFieldsAdapter.getInitialState()
const lveCalendarConfigInitialState = lveCalendarConfigAdapter.getInitialState()

const leavePeriodYearInitialState = leavePeriodYearAdapter.getInitialState()
const leavePeriodInitialState = leavePeriodAdapter.getInitialState()
const leaveEntitlementViewInitialState = leaveEntitlementViewAdapter.getInitialState()
const leaveEntitlementInitialState = leaveEntitlementAdapter.getInitialState()
const leEarnedInitialState = leEarnedAdapter.getInitialState()
const leaveRecordViewInitialState = leaveRecordViewAdapter.getInitialState()
const leaveRecordInitialState = leaveRecordAdapter.getInitialState()
const leaveRecordDtlsInitialState = leaveRecordDtlsAdapter.getInitialState()
const leaveApplyFormInfoInitialState = leaveApplyFormInfoAdapter.getInitialState()
const leaveRecordAttachmentInitialState = leaveRecordAttachmentAdapter.getInitialState()
const leaveRecordApprovalHistoriesInitialState = leaveRecordApprovalHistoriesAdapter.getInitialState()
const leaveGrantInitialState = leaveGrantAdapter.getInitialState()
const leavePendingApprovalsViewIntitalState = leavePendingApprovalsViewAdapter.getInitialState()
const entitledLeaveTypeIntitalState = entitledLeaveTypeAdapter.getInitialState()

const leaveBuddiesInitialState = leaveBuddiesAdapter.getInitialState()
const leaveBuddyEmInitialState = leaveBuddyEmAdapter.getInitialState()

const googleCalendarsInitialState = googleCalendarsAdapter.getInitialState()
const googleCalendarConnectionInitialState = googleCalendarConnectionsAdapter.getInitialState()

const initialState: LeaveRootState = {
  leaveTypes: leaveTypesInitialState,
  leaveTypesLoading: false,
  leaveTypesView: leaveTypesViewInitialState,
  leaveTypesViewLoading: false,
  grantLeaveTypes: leaveTypesInitialState,
  grantLeaveTypesLoading: false,
  ltSysColumns: {},
  ltSysColumnsLoading: {},
  ltSettings: {},
  ltSettingsLoading: {},
  ltPolicies: {},
  ltPoliciesLoading: {},
  ltPoliciesExtra: {},
  ltPoliciesExtraLoading: {},
  ltProrationM: {},
  ltProrationMLoading: {},
  lpEntitlements: {},
  lpEntitlementsLoading: {},
  lpLeaveGroups: {},
  lpLeaveGroupsLoading: {},
  sysLeaveTypes: sysLeaveTypesInitialState,
  sysLeaveTypesLoading: false,
  sysLtFields: {},
  sysLtFieldsLoading: {},
  lveCalendarConfig: lveCalendarConfigInitialState,
  lveCalendarConfigLoading: false,

  leaveExpanded: false,
  leaveLoading: false,
  leavePeriodYears: leavePeriodYearInitialState,
  leavePeriodYearsLoading: false,
  leavePeriods: leavePeriodInitialState,
  leavePeriodsLoading: false,
  leaveEntitlementsView: leaveEntitlementViewInitialState,
  leaveEntitlementsViewLoading: false,
  leaveEntitlementsViewRefetch: 0,
  leaveEntitlements: leaveEntitlementInitialState,
  leaveEntitlementsLoading: false,
  leEarned: {},
  leEarnedLoading: {},
  leaveRecordsView: leaveRecordViewInitialState,
  leaveRecordsViewLoading: false,
  leaveRecordsViewRefetch: 0,
  leaveRecords: leaveRecordInitialState,
  leaveRecordsLoading: false,
  leaveRecordDtls: {},
  leaveRecordDtlsLoading: {},
  leaveRecordAttachment: leaveRecordAttachmentInitialState,
  leaveRecordAttachmentLoading: false,
  leaveRecordApprovalHistories: {},
  leaveRecordApprovalHistoriesLoading: {},
  leaveApplyFormInfo: {},
  leaveApplyFormInfoLoading: {},
  leaveGrants: {},
  leaveGrantsLoading: {},
  leavePendingApprovalsView: leavePendingApprovalsViewIntitalState,
  leavePendingApprovalsViewLoading: false,
  leavePendingApprovalsViewRefetch: 0,
  entitledLeaveTypes: {},
  entitledLeaveTypesLoading: {},

  leaveBuddies: leaveBuddiesInitialState,
  leaveBuddiesLoading: false,
  leaveBuddyEm: {},
  leaveBuddyEmLoading: {},

  // Google Calendar integration
  googleCalendarState: '',
  googleCalendarStateTimestamp: 0,
  googleCalendarRedirectUrl: '',
  googleCalendars: googleCalendarsInitialState,
  googleCalendarsLoading: false,
  googleCalendarConnections: googleCalendarConnectionInitialState,
  googleCalendarConnectionsLoading: false
}

const leaveSlice = createSlice({
  name: 'leave',
  initialState,
  reducers: {
    replaceLeaveTypes: (state, action: PayloadAction<LeaveTypeState[]>) => {
      leaveTypesAdapter.setAll(state.leaveTypes, action.payload)
    },
    setLeaveType: (state, action: PayloadAction<LeaveTypeState>) => {
      leaveTypesAdapter.upsertOne(state.leaveTypes, action.payload)
    },
    removeLeaveType: (state, action: PayloadAction<string>) => {
      leaveTypesAdapter.removeOne(state.leaveTypes, action.payload)
    },
    setLeaveTypesLoading: (state, action: PayloadAction<boolean>) => {
      state.leaveTypesLoading = action.payload
    },
    replaceLeaveTypesView: (state, action: PayloadAction<LeaveTypeState[]>) => {
      leaveTypesViewAdapter.setAll(state.leaveTypesView, action.payload)
    },
    setLeaveTypesViewLoading: (state, action: PayloadAction<boolean>) => {
      state.leaveTypesViewLoading = action.payload
    },
    replaceGrantLeaveTypes: (state, action: PayloadAction<LeaveTypeState[]>) => {
      leaveTypesAdapter.setAll(state.grantLeaveTypes, action.payload)
    },
    setGrantLeaveTypesLoading: (state, action: PayloadAction<boolean>) => {
      state.grantLeaveTypesLoading = action.payload
    },
    replaceLtSysColumns: (state, action: PayloadAction<{ leaveTypeId: string; data: string[] }>) => {
      const { leaveTypeId, data } = action.payload
      state.ltSysColumns[leaveTypeId] = state.ltSysColumns[leaveTypeId] || []
      state.ltSysColumns[leaveTypeId] = data
    },
    setLtSysColumnsLoading: (state, action: PayloadAction<{ leaveTypeId: string; loading: boolean }>) => {
      state.ltSysColumnsLoading[action.payload.leaveTypeId] = action.payload.loading
    },
    replaceLtSettings: (state, action: PayloadAction<{ leaveTypeId: string; data: LtSettingState[] }>) => {
      const { leaveTypeId, data } = action.payload
      state.ltSettings[leaveTypeId] = state.ltSettings[leaveTypeId] || ltSettingsInitialState
      ltSettingsAdapter.setAll(state.ltSettings[leaveTypeId]!, data)
    },
    setLtSettingsLoading: (state, action: PayloadAction<{ leaveTypeId: string; loading: boolean }>) => {
      const { leaveTypeId, loading } = action.payload
      state.ltSettings[leaveTypeId] = state.ltSettings[leaveTypeId] || ltSettingsInitialState
      state.ltSettingsLoading[leaveTypeId] = loading
    },
    replaceLtPolicies: (state, action: PayloadAction<{ leaveTypeId: string; data: LtPolicyState[] }>) => {
      const { leaveTypeId, data } = action.payload
      state.ltPolicies[leaveTypeId] = state.ltPolicies[leaveTypeId] || ltPoliciesInitialState
      ltPoliciesAdapter.setAll(state.ltPolicies[leaveTypeId]!, data)
    },
    setLtPolicy: (state, action: PayloadAction<{ leaveTypeId: string; data: LtPolicyState }>) => {
      const { leaveTypeId, data } = action.payload
      state.ltPolicies[leaveTypeId] = state.ltPolicies[leaveTypeId] || ltPoliciesInitialState
      ltPoliciesAdapter.upsertOne(state.ltPolicies[leaveTypeId]!, data)
    },
    removeLtPolicy: (state, action: PayloadAction<{ leaveTypeId: string; id: string }>) => {
      ltPoliciesAdapter.removeOne(state.ltPolicies[action.payload.leaveTypeId]!, action.payload.id)
    },
    setLtPoliciesLoading: (state, action: PayloadAction<{ leaveTypeId: string; loading: boolean }>) => {
      const { leaveTypeId, loading } = action.payload
      state.ltPolicies[leaveTypeId] = state.ltPolicies[leaveTypeId] || ltPoliciesInitialState
      state.ltPoliciesLoading[leaveTypeId] = loading
    },
    replaceLtPoliciesExtra: (state, action: PayloadAction<{ leaveTypeId: string; data: LtPolicyExtraState[] }>) => {
      const { leaveTypeId, data } = action.payload
      state.ltPoliciesExtra[leaveTypeId] = state.ltPoliciesExtra[leaveTypeId] || ltPoliciesExtraInitialState
      ltPoliciesExtraAdapter.setAll(state.ltPoliciesExtra[leaveTypeId]!, data)
    },
    setLtPolicyExtra: (state, action: PayloadAction<{ leaveTypeId: string; data: LtPolicyExtraState }>) => {
      const { leaveTypeId, data } = action.payload
      state.ltPoliciesExtra[leaveTypeId] = state.ltPoliciesExtra[leaveTypeId] || ltPoliciesExtraInitialState
      ltPoliciesExtraAdapter.upsertOne(state.ltPoliciesExtra[leaveTypeId]!, data)
    },
    removeLtPolicyExtra: (state, action: PayloadAction<{ leaveTypeId: string; id: string }>) => {
      ltPoliciesExtraAdapter.removeOne(state.ltPoliciesExtra[action.payload.leaveTypeId]!, action.payload.id)
    },
    setLtPoliciesExtraLoading: (state, action: PayloadAction<{ leaveTypeId: string; loading: boolean }>) => {
      const { leaveTypeId, loading } = action.payload
      state.ltPoliciesExtra[leaveTypeId] = state.ltPoliciesExtra[leaveTypeId] || ltPoliciesExtraInitialState
      state.ltPoliciesExtraLoading[leaveTypeId] = loading
    },
    replaceLtProrationM: (state, action: PayloadAction<{ leaveTypeId: string; data: LtProrationMState[] }>) => {
      const { leaveTypeId, data } = action.payload
      state.ltProrationM[leaveTypeId] = state.ltProrationM[leaveTypeId] || ltProrationMInitialState
      ltProrationMAdapter.setAll(state.ltProrationM[leaveTypeId]!, data)
    },
    setLtProrationM: (state, action: PayloadAction<{ leaveTypeId: string; data: LtProrationMState }>) => {
      const { leaveTypeId, data } = action.payload
      state.ltProrationM[leaveTypeId] = state.ltProrationM[leaveTypeId] || ltProrationMInitialState
      ltProrationMAdapter.upsertOne(state.ltProrationM[leaveTypeId]!, data)
    },
    removeLtProrationM: (state, action: PayloadAction<{ leaveTypeId: string; id: string }>) => {
      ltProrationMAdapter.removeOne(state.ltProrationM[action.payload.leaveTypeId]!, action.payload.id)
    },
    setLtProrationMLoading: (state, action: PayloadAction<{ leaveTypeId: string; loading: boolean }>) => {
      const { leaveTypeId, loading } = action.payload
      state.ltProrationM[leaveTypeId] = state.ltProrationM[leaveTypeId] || ltProrationMInitialState
      state.ltProrationMLoading[leaveTypeId] = loading
    },
    replaceLpEntitlements: (state, action: PayloadAction<{ id: string; data: LpEntitlementState[] }>) => {
      state.lpEntitlements[action.payload.id] = state.lpEntitlements[action.payload.id] || lpEntitlementsInitialState
      lpEntitlementsAdapter.setAll(state.lpEntitlements[action.payload.id]!, action.payload.data)
    },
    setLpEntitlementsLoading: (state, action: PayloadAction<{ id: string; loading: boolean }>) => {
      state.lpEntitlements[action.payload.id] = state.lpEntitlements[action.payload.id] || lpEntitlementsInitialState
      state.lpEntitlementsLoading[action.payload.id] = action.payload.loading
    },
    replaceLpLeaveGroups: (state, action: PayloadAction<{ id: string; data: LpLeaveGroupState[] }>) => {
      state.lpLeaveGroups[action.payload.id] = state.lpLeaveGroups[action.payload.id] || lpLeaveGroupsInitialState
      lpLeaveGroupsAdapter.setAll(state.lpLeaveGroups[action.payload.id]!, action.payload.data)
    },
    setLpLeaveGroupsLoading: (state, action: PayloadAction<{ id: string; loading: boolean }>) => {
      state.lpLeaveGroups[action.payload.id] = state.lpLeaveGroups[action.payload.id] || lpLeaveGroupsInitialState
      state.lpLeaveGroupsLoading[action.payload.id] = action.payload.loading
    },
    replaceSysLeaveTypes: (state, action: PayloadAction<SysLeaveTypeState[]>) => {
      sysLeaveTypesAdapter.setAll(state.sysLeaveTypes, action.payload)
    },
    setSysLeaveTypesLoading: (state, action: PayloadAction<boolean>) => {
      state.sysLeaveTypesLoading = action.payload
    },
    replaceSysLtFields: (state, action: PayloadAction<{ code: string; data: SysLtFieldState[] }>) => {
      state.sysLtFields[action.payload.code] = state.sysLtFields[action.payload.code] || sysLtFieldsInitialState
      sysLtFieldsAdapter.setAll(state.sysLtFields[action.payload.code]!, action.payload.data)
    },
    setSysLtFieldsLoading: (state, action: PayloadAction<{ code: string; loading: boolean }>) => {
      state.sysLtFields[action.payload.code] = state.sysLtFields[action.payload.code] || sysLtFieldsInitialState
      state.sysLtFieldsLoading[action.payload.code] = action.payload.loading
    },
    setLveCalendarConfig: (state, action: PayloadAction<LveCalendarConfigState>) => {
      lveCalendarConfigAdapter.upsertOne(state.lveCalendarConfig, action.payload)
    },
    setLveCalendarConfigLoading: (state, action: PayloadAction<boolean>) => {
      state.lveCalendarConfigLoading = action.payload
    },

    setLeaveExpanded: (state, action: PayloadAction<boolean>) => {
      state.leaveExpanded = action.payload
    },
    setLeaveLoading: (state, action: PayloadAction<boolean>) => {
      state.leaveLoading = action.payload
    },

    // leavePeriodYears
    setLeavePeriodYearsLoading: (state, action: PayloadAction<boolean>) => {
      state.leavePeriodYearsLoading = action.payload
    },
    replaceLeavePeriodYears: (state, action: PayloadAction<LeavePeriodYearState[]>) => {
      leavePeriodYearAdapter.setAll(state.leavePeriodYears, action.payload)
    },
    setLeavePeriodsLoading: (state, action: PayloadAction<boolean>) => {
      state.leavePeriodsLoading = action.payload
    },
    replaceLeavePeriods: (state, action: PayloadAction<LeavePeriodState[]>) => {
      leavePeriodAdapter.setAll(state.leavePeriods, action.payload)
    },

    // leaveEntitlementsView
    refetchLeaveEntitlementsView: state => {
      state.leaveEntitlementsViewRefetch += 1
    },
    setLeaveEntitlementsViewLoading: (state, action: PayloadAction<boolean>) => {
      state.leaveEntitlementsViewLoading = action.payload
    },
    setLeaveEntitlementsView: (state, action: PayloadAction<LeaveEntitlementViewState>) => {
      leaveEntitlementViewAdapter.upsertOne(state.leaveEntitlementsView, action.payload)
    },

    // leaveEntitlements
    setLeaveEntitlementsLoading: (state, action: PayloadAction<boolean>) => {
      state.leaveEntitlementsLoading = action.payload
    },
    setLeaveEntitlement: (state, action: PayloadAction<LeaveEntitlementState>) => {
      leaveEntitlementAdapter.upsertOne(state.leaveEntitlements, action.payload)
    },

    // leEarned
    setLeEarnedLoading: (state, action: PayloadAction<{ entId: string; loading: boolean }>) => {
      const { entId, loading } = action.payload
      state.leEarned[entId] = state.leEarned[entId] || leEarnedInitialState
      state.leEarnedLoading[entId] = loading
    },
    replaceLeEarned: (state, action: PayloadAction<{ entId: string; data: LeEarnedState[] }>) => {
      const { entId, data } = action.payload
      state.leEarned[entId] = state.leEarned[entId] || leEarnedInitialState
      leEarnedAdapter.setAll(state.leEarned[entId], data)
    },

    // leaveRecordsView
    refetchLeaveRecordsView: state => {
      state.leaveRecordsViewRefetch += 1
    },
    setLeaveRecordsViewLoading: (state, action: PayloadAction<boolean>) => {
      state.leaveRecordsViewLoading = action.payload
    },
    setLeaveRecordsView: (state, action: PayloadAction<LeaveRecordViewState>) => {
      leaveRecordViewAdapter.upsertOne(state.leaveRecordsView, action.payload)
    },

    // leaveRecords
    setLeaveRecordsLoading: (state, action: PayloadAction<boolean>) => {
      state.leaveRecordsLoading = action.payload
    },
    setLeaveRecords: (state, action: PayloadAction<LeaveRecordState>) => {
      leaveRecordAdapter.upsertOne(state.leaveRecords, action.payload)
    },
    removeLeaveRecord: (state, action: PayloadAction<string>) => {
      leaveRecordAdapter.removeOne(state.leaveRecords, action.payload)
    },

    // leaveRecordDlts
    setLeaveRecordDtlsLoading: (state, action: PayloadAction<{ id: string; loading: boolean }>) => {
      const { id, loading } = action.payload
      state.leaveRecordDtls[id] = state.leaveRecordDtls[id] || leaveRecordDtlsInitialState
      state.leaveRecordDtlsLoading[id] = loading
    },
    replaceLeaveRecordDtls: (state, action: PayloadAction<{ id: string; data: LeaveRecordDtlState[] }>) => {
      const { id, data } = action.payload
      state.leaveRecordDtls[id] = state.leaveRecordDtls[id] || leaveRecordDtlsInitialState
      leaveRecordDtlsAdapter.setAll(state.leaveRecordDtls[id]!, data)
    },

    // leaveApplyFormInfo
    setLeaveApplyFormInfoLoading: (state, action: PayloadAction<{ emLtId: string; loading: boolean }>) => {
      const { emLtId, loading } = action.payload
      state.leaveApplyFormInfo[emLtId] = state.leaveApplyFormInfo[emLtId] || leaveApplyFormInfoInitialState
      state.leaveApplyFormInfoLoading[emLtId] = loading
    },
    replaceLeaveApplyFormInfo: (state, action: PayloadAction<{ emLtId: string; data: LeaveApplyFormInfoState[] }>) => {
      const { emLtId, data } = action.payload
      state.leaveApplyFormInfo[emLtId] = state.leaveApplyFormInfo[emLtId] || leaveApplyFormInfoInitialState
      leaveApplyFormInfoAdapter.setAll(state.leaveApplyFormInfo[emLtId]!, data)
    },

    // leaveRecordAttachment
    setLeaveRecordAttachmentLoading: (state, action: PayloadAction<boolean>) => {
      state.leaveRecordAttachmentLoading = action.payload
    },
    replaceLeaveRecordAttachment: (state, action: PayloadAction<LeaveRecordAttachmentState[]>) => {
      leaveRecordAttachmentAdapter.setAll(state.leaveRecordAttachment, action.payload)
    },

    // leaveRecordApprovalHistories
    setLeaveRecordApprovalHistoriesLoading: (state, action: PayloadAction<{ id: string; loading: boolean }>) => {
      const { id, loading } = action.payload
      state.leaveRecordApprovalHistories[id] =
        state.leaveRecordApprovalHistories[id] || leaveRecordApprovalHistoriesInitialState
      state.leaveRecordApprovalHistoriesLoading[id] = loading
    },
    replaceLeaveRecordApprovalHistories: (
      state,
      action: PayloadAction<{ id: string; data: LeaveRecordApprovalHistoryState[] }>
    ) => {
      const { id, data } = action.payload
      state.leaveRecordApprovalHistories[id] =
        state.leaveRecordApprovalHistories[id] || leaveRecordApprovalHistoriesInitialState
      leaveRecordApprovalHistoriesAdapter.setAll(state.leaveRecordApprovalHistories[id]!, data)
    },

    // leaveGrants
    setLeaveGrants: (state, action: PayloadAction<{ employeeId: string; data: LeaveGrantState[] }>) => {
      const { employeeId, data } = action.payload
      state.leaveGrants[employeeId] = state.leaveGrants[employeeId] || leaveGrantInitialState
      leaveGrantAdapter.upsertMany(state.leaveGrants[employeeId]!, data)
    },
    replaceLeaveGrants: (state, action: PayloadAction<{ employeeId: string; data: LeaveGrantState[] }>) => {
      const { employeeId, data } = action.payload
      state.leaveGrants[employeeId] = state.leaveGrants[employeeId] || leaveGrantInitialState
      leaveGrantAdapter.setAll(state.leaveGrants[employeeId]!, data)
    },
    removeLeaveGrant: (state, action: PayloadAction<{ employeeId: string; id: string }>) => {
      const { employeeId, id } = action.payload
      state.leaveGrants[employeeId] = state.leaveGrants[employeeId] || leaveGrantInitialState
      leaveGrantAdapter.removeOne(state.leaveGrants[employeeId], id)
    },
    removeLeaveGrants: (state, action: PayloadAction<{ employeeId: string; ids: string[] }>) => {
      const { employeeId, ids } = action.payload
      state.leaveGrants[employeeId] = state.leaveGrants[employeeId] || leaveGrantInitialState
      leaveGrantAdapter.removeMany(state.leaveGrants[employeeId], ids)
    },
    setLeaveGrantsLoading: (state, action: PayloadAction<{ employeeId: string; loading: boolean }>) => {
      const { employeeId, loading } = action.payload
      state.leaveGrants[employeeId] = state.leaveGrants[employeeId] || leaveGrantInitialState
      state.leaveGrantsLoading[employeeId] = loading
    },
    replaceLeaveGrantBulk: (state, action: PayloadAction<LeaveGrantBulkState>) => {
      state.leaveGrantBulk = action.payload
    },
    replaceLeaveGrantBulkVerify: (state, action: PayloadAction<LeaveGrantBulkVerifyState>) => {
      state.leaveGrantBulkVerify = action.payload
    },

    // leavePendingApprovalsView
    refetchLeavePendingApprovalsView: state => {
      state.leavePendingApprovalsViewRefetch += 1
    },
    setLeavePendingApprovalsViewLoading: (state, action: PayloadAction<boolean>) => {
      state.leavePendingApprovalsViewLoading = action.payload
    },
    setLeavePendingApprovalsView: (state, action: PayloadAction<LeavePendingApprovalViewState>) => {
      leavePendingApprovalsViewAdapter.upsertOne(state.leavePendingApprovalsView, action.payload)
    },

    setEntitledLeaveTypesLoading: (state, action: PayloadAction<{ employeeId: string; loading: boolean }>) => {
      const { employeeId, loading } = action.payload
      state.entitledLeaveTypes[employeeId] = state.entitledLeaveTypes[employeeId] || entitledLeaveTypeIntitalState
      state.entitledLeaveTypesLoading[employeeId] = loading
    },
    replaceEntitledLeaveTypes: (
      state,
      action: PayloadAction<{ employeeId: string; data: EntitledLeaveTypeState[] }>
    ) => {
      const { employeeId, data } = action.payload
      state.entitledLeaveTypes[employeeId] = state.entitledLeaveTypes[employeeId] || entitledLeaveTypeIntitalState
      entitledLeaveTypeAdapter.setAll(state.entitledLeaveTypes[employeeId]!, data)
    },
    replacePendingLeaveResult: (state, action: PayloadAction<PendingLeaveResultState>) => {
      state.pendingLeaveResult = action.payload
    },
    replaceNextEntitlementResult: (state, action: PayloadAction<NextEntitlementResultState>) => {
      state.nextEntitlementResult = action.payload
    },

    // leaveBuddies
    replaceLeaveBuddies: (state, action: PayloadAction<LeaveBuddyState[]>) => {
      leaveBuddiesAdapter.setAll(state.leaveBuddies, action.payload)
    },
    setLeaveBuddy: (state, action: PayloadAction<LeaveBuddyState>) => {
      leaveBuddiesAdapter.upsertOne(state.leaveBuddies, action.payload)
    },
    removeLeaveBuddy: (state, action: PayloadAction<string>) => {
      leaveBuddiesAdapter.removeOne(state.leaveBuddies, action.payload)
    },
    setLeaveBuddiesLoading: (state, action: PayloadAction<boolean>) => {
      state.leaveBuddiesLoading = action.payload
    },

    // leaveBuddyEm
    replaceLeaveBuddyEm: (state, action: PayloadAction<{ id: string; data: LeaveBuddyEmState[] }>) => {
      state.leaveBuddyEm[action.payload.id] = state.leaveBuddyEm[action.payload.id] || leaveBuddyEmInitialState
      leaveBuddyEmAdapter.setAll(state.leaveBuddyEm[action.payload.id]!, action.payload.data)
    },
    removeLeaveBuddyEmByBuddy: (state, action: PayloadAction<string>) => {
      const leaveBuddyId = action.payload
      leaveBuddyEmAdapter.removeAll(state.leaveBuddyEm[leaveBuddyId]!)
    },
    setLeaveBuddyEmLoading: (state, action: PayloadAction<{ id: string; loading: boolean }>) => {
      state.leaveBuddyEm[action.payload.id] = state.leaveBuddyEm[action.payload.id] || leaveBuddyEmInitialState
      state.leaveBuddyEmLoading[action.payload.id] = action.payload.loading
    },

    // Google Calendar integration
    setGoogleCalendarState: (state, action: PayloadAction<string>) => {
      state.googleCalendarState = action.payload
      state.googleCalendarStateTimestamp = new Date().getTime()
    },
    setGoogleCalendarRedirectUrl: (state, action: PayloadAction<string>) => {
      state.googleCalendarRedirectUrl = action.payload
    },
    replaceGoogleCalendars: (state, action: PayloadAction<GoogleCalendarState[]>) => {
      googleCalendarsAdapter.setAll(state.googleCalendars, action.payload)
    },
    setGoogleCalendar: (state, action: PayloadAction<GoogleCalendarState>) => {
      googleCalendarsAdapter.upsertOne(state.googleCalendars, action.payload)
    },
    removeGoogleCalendar: (state, action: PayloadAction<string>) => {
      googleCalendarsAdapter.removeOne(state.googleCalendars, action.payload)
    },
    setGoogleCalendarsLoading: (state, action: PayloadAction<boolean>) => {
      state.googleCalendarsLoading = action.payload
    },
    setGoogleCalendarConnection: (state, action: PayloadAction<GoogleCalendarConnectionState>) => {
      googleCalendarConnectionsAdapter.upsertOne(state.googleCalendarConnections, action.payload)
    },
    removeGoogleCalendarConnection: (state, action: PayloadAction<string>) => {
      googleCalendarConnectionsAdapter.removeOne(state.googleCalendarConnections, action.payload)
    },
    setGoogleCalendarConnectionsLoading: (state, action: PayloadAction<boolean>) => {
      state.googleCalendarConnectionsLoading = action.payload
    }
  }
})

export const {
  replaceLeaveTypes,
  setLeaveType,
  removeLeaveType,
  setLeaveTypesLoading,
  replaceLeaveTypesView,
  setLeaveTypesViewLoading,
  replaceGrantLeaveTypes,
  setGrantLeaveTypesLoading,
  replaceLtSysColumns,
  setLtSysColumnsLoading,
  replaceLtSettings,
  setLtSettingsLoading,
  replaceLtPolicies,
  setLtPolicy,
  removeLtPolicy,
  setLtPoliciesLoading,
  replaceLtPoliciesExtra,
  setLtPolicyExtra,
  removeLtPolicyExtra,
  setLtPoliciesExtraLoading,
  replaceLtProrationM,
  setLtProrationM,
  removeLtProrationM,
  setLtProrationMLoading,
  setLveCalendarConfig,
  setLveCalendarConfigLoading,

  // TODO: not in used??
  replaceLpEntitlements,
  setLpEntitlementsLoading,
  replaceLpLeaveGroups,
  setLpLeaveGroupsLoading,

  replaceSysLeaveTypes,
  setSysLeaveTypesLoading,
  replaceSysLtFields,
  setSysLtFieldsLoading,

  setLeaveExpanded,
  setLeaveLoading,

  // leavePeriod
  setLeavePeriodYearsLoading,
  replaceLeavePeriodYears,
  setLeavePeriodsLoading,
  replaceLeavePeriods,

  // leaveEntitlements
  refetchLeaveEntitlementsView,
  setLeaveEntitlementsViewLoading,
  setLeaveEntitlementsView,
  setLeaveEntitlementsLoading,
  setLeaveEntitlement,

  // leEarned
  setLeEarnedLoading,
  replaceLeEarned,

  // leaveRecordsView
  refetchLeaveRecordsView,
  setLeaveRecordsViewLoading,
  setLeaveRecordsView,

  // leaveRecords
  setLeaveRecordsLoading,
  setLeaveRecords,
  removeLeaveRecord,

  // leaveRecordDtls
  setLeaveRecordDtlsLoading,
  replaceLeaveRecordDtls,

  // leaveApplyFormInfo
  setLeaveApplyFormInfoLoading,
  replaceLeaveApplyFormInfo,

  // leaveRecordAttachment
  setLeaveRecordAttachmentLoading,
  replaceLeaveRecordAttachment,

  // leaveRecordApprovalHistories
  setLeaveRecordApprovalHistoriesLoading,
  replaceLeaveRecordApprovalHistories,

  // leaveGrants
  replaceLeaveGrants,
  setLeaveGrants,
  removeLeaveGrant,
  removeLeaveGrants,
  setLeaveGrantsLoading,
  replaceLeaveGrantBulk,
  replaceLeaveGrantBulkVerify,

  // leavePendingApprovalsView
  refetchLeavePendingApprovalsView,
  setLeavePendingApprovalsViewLoading,
  setLeavePendingApprovalsView,

  // entitledLeaveTypes
  setEntitledLeaveTypesLoading,
  replaceEntitledLeaveTypes,

  replacePendingLeaveResult,
  replaceNextEntitlementResult,

  // leaveBuddies
  replaceLeaveBuddies,
  setLeaveBuddy,
  removeLeaveBuddy,
  setLeaveBuddiesLoading,

  // leaveBuddyEm
  replaceLeaveBuddyEm,
  removeLeaveBuddyEmByBuddy,
  setLeaveBuddyEmLoading,

  // googleCalendar
  setGoogleCalendarState,
  setGoogleCalendarRedirectUrl,
  replaceGoogleCalendars,
  setGoogleCalendar,
  removeGoogleCalendar,
  setGoogleCalendarsLoading,
  setGoogleCalendarConnection,
  removeGoogleCalendarConnection,
  setGoogleCalendarConnectionsLoading
} = leaveSlice.actions

export const { selectById: selectLeaveTypeById } = leaveTypesAdapter.getSelectors(
  (state: StoreState) => state.leave.leaveTypes
)

export const leaveReducers = { leave: persistReducer<LeaveRootState>(leavePersistConfig, leaveSlice.reducer) }
