import { ThunkResult } from '~/types/store'
import { apiDisconnectGoogleCalendar } from '../api/google-calendar.api'
import { removeGoogleCalendarConnection } from '../reducers'
import { showError, showSuccess } from '~/utils'

export const disconnectGoogleCalendar = (id: string): ThunkResult<void> => {
  return async dispatch => {
    if (!id) return

    const { status, errors, message, errorData } = await apiDisconnectGoogleCalendar(id)
    if (status) {
      dispatch(removeGoogleCalendarConnection(id))
      showSuccess('Disconnected')
    } else {
      if (Object.keys(errors).length === 0) {
        showError(message, errorData)
      }
    }
    return { errors }
  }
}
