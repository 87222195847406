import { setGoogleCalendar } from '../reducers'
import { apiGetGoogleCalendar } from '../api/google-calendar.api'
import { ThunkResult } from '~/types/store'
import { DEFAULT_FETCH_OPTIONS, FetchOptions } from '~/types/common'

export const fetchGoogleCalendar = (id: string, options: FetchOptions = DEFAULT_FETCH_OPTIONS): ThunkResult<void> => {
  return async (dispatch, getState) => {
    if (!id) return

    if (options.strategy === 'when-empty') {
      const hasData = getState().attendance.attendanceGroups?.entities[id]
      if (hasData) return
    }

    const { result, status } = await apiGetGoogleCalendar(id)
    if (status) {
      dispatch(setGoogleCalendar(result))
    }
  }
}
