import React, { FC, useCallback, useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import confirm from 'antd/lib/modal/confirm'
import { Button, PageHeader } from '~/core-components'
import { DocumentTitle } from '~/components'
import { attendanceMenus, useMasterMenu } from '~/features/master'
import { usePermissionGate } from '~/features/iam'
import { SETTINGS_ROUTES } from '~/routes/routes'
import { Permission, PermissionAction } from '~/constants'
import { MenuItem } from '~/types/common'
import { dispatch } from '~/stores/store'
import { useGoogleCalendar, useGoogleCalendars } from '../../hooks'
import { deleteGoogleCalendar, initiateGoogleCalendarAuthentication } from '../../actions'
import { GoogleCalendarAccount } from './components/GoogleCalendarAccount'
import { GoogleCalendarSetting } from './components/GoogleCalendarSetting'
import './GoogleCalendar.less'

interface GoogleCalendarProps {}

interface GoogleCalendarParams {
  id: string
}

const routes = [
  {
    path: SETTINGS_ROUTES.main,
    breadcrumbName: 'Settings'
  },
  {
    path: SETTINGS_ROUTES.googleCalendars,
    breadcrumbName: 'Overview'
  },
  {
    path: '',
    breadcrumbName: 'Google Calendar integration'
  }
]

export const GoogleCalendar: FC<GoogleCalendarProps> = () => {
  const { id } = useParams<GoogleCalendarParams>()
  const [googleCalendars] = useGoogleCalendars()
  const [googleCalendar] = useGoogleCalendar(id)
  const [editing, setEditing] = useState(false)
  const [connecting, setConnecting] = useState(false)
  const history = useHistory()
  const canModify = usePermissionGate(Permission.googleCalendar, PermissionAction.Modify)
  const { setMenus } = useMasterMenu()

  const handleConnect = useCallback(async () => {
    try {
      setConnecting(true)
      await dispatch(initiateGoogleCalendarAuthentication())
    } finally {
      setConnecting(false)
    }
  }, [])

  useEffect(() => {
    const menus: MenuItem[] = googleCalendars.map(s => ({
      path: SETTINGS_ROUTES.googleCalendar.replace(':id', s!.id),
      value: s!.name,
      sysPermissionId: Permission.googleCalendar
    }))

    if (canModify)
      menus.push({
        path: (
          <Button type="dashed" onClick={handleConnect} loading={connecting} block>
            Add Google account
          </Button>
        ),
        value: '',
        sysPermissionId: ''
      })

    setMenus(menus)
    return () => {
      setMenus(attendanceMenus)
    }
  }, [setMenus, googleCalendars, canModify, handleConnect, connecting])

  const handleEdit = useCallback(() => setEditing(true), [])
  const handleView = useCallback(() => setEditing(false), [])

  const handleDelete = useCallback(() => {
    if (id && googleCalendar) {
      confirm({
        title: 'Delete Google integration',
        content: `Do you want to delete Google integration "${googleCalendar?.name}"?`,
        onOk: () => {
          dispatch(deleteGoogleCalendar(id))
          history.push(SETTINGS_ROUTES.googleCalendars)
        },
        okText: 'Delete',
        okType: 'danger'
      })
    }
  }, [id, googleCalendar, history])

  return (
    <div id="google-calendar" className="google-calendar">
      <DocumentTitle title="Google Calendar Integration" />
      <PageHeader
        title="Google Calendar integration"
        containerId="google-calendar"
        breadcrumb={{ routes }}
        extra={
          canModify &&
          editing && (
            <Button key="delete" onClick={handleDelete}>
              Delete
            </Button>
          )
        }
      />
      <div className="google-calendar__body">
        <GoogleCalendarAccount
          googleCalendar={googleCalendar}
          onEdit={handleEdit}
          onSave={handleView}
          onCancel={handleView}
        />
        <GoogleCalendarSetting
          googleCalendar={googleCalendar}
          onEdit={handleEdit}
          onSave={handleView}
          onCancel={handleView}
        />
      </div>
    </div>
  )
}
