import { createSelector } from '@reduxjs/toolkit'
import { StoreState } from '~/types/store'
import { GoogleCalendarState } from '../types'

export const selectGoogleCalendars = createSelector(
  (state: StoreState) => state.leave.googleCalendars,
  (googleCalendarsState): GoogleCalendarState[] => {
    const googleCalendars = Object.values(googleCalendarsState?.entities || {}) as GoogleCalendarState[]
    return googleCalendars.sort((a, b) => a!.name?.localeCompare(b!.name))
  }
)
