import React, { CSSProperties, FC, useCallback, useEffect, useMemo, useState } from 'react'
import { Link as RouterLink } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { Button, Card, ColumnsType, Link, PageHeader, Table } from '~/core-components'
import { DocumentTitle } from '~/components'
import { dispatch } from '~/stores/store'
import { usePermissionGate } from '~/features/iam'
import { Permission, PermissionAction } from '~/constants'
import { SETTINGS_ROUTES } from '~/routes/routes'
import { StoreState } from '~/types/store'
import { fetchWorkflows } from '../actions'
import { WfCategory, WorkflowState } from '../types'
import { config } from '../config'
import { MutateWorkflowDrawer } from './components/MutateWorkflowDrawer'
import './Workflows.less'

interface WorkflowsProps {
  category: WfCategory
}

type WorkflowTable = WorkflowState

interface DrawerState {
  visible: boolean
  data?: WorkflowState
}
const DEFAULT_DRAWER_STATE: DrawerState = { visible: false }

const labelRow1Style: CSSProperties = { lineHeight: 1.1 }

export const Workflows: FC<WorkflowsProps> = ({ category }) => {
  const [drawerState, setDrawerState] = useState<DrawerState>(DEFAULT_DRAWER_STATE)
  const canModify = usePermissionGate(Permission.lveApproval, PermissionAction.Modify)
  const workflows = useSelector(
    (state: StoreState) => Object.values(state.workflow.workflows[category]?.entities || {}) as WorkflowState[]
  )
  const loading = useSelector((state: StoreState) => state.workflow.workflowsLoading[category])

  const routes = useMemo(
    () => [
      {
        path: SETTINGS_ROUTES.main,
        breadcrumbName: 'Settings'
      },
      {
        path: '',
        breadcrumbName: config[category].pageTitle
      }
    ],
    [category]
  )

  useEffect(() => {
    dispatch(fetchWorkflows(category))
  }, [category])

  const handleAddWorkflow = useCallback(() => {
    setDrawerState({ visible: true, data: undefined })
  }, [])

  const handleEditWorkflow = useCallback((workflow: WorkflowState) => {
    setDrawerState({ visible: true, data: workflow })
  }, [])

  const handleCloseDrawer = useCallback(() => {
    setDrawerState(DEFAULT_DRAWER_STATE)
  }, [])

  const columns: ColumnsType<WorkflowTable> = [
    {
      title: 'Workflow',
      key: 'name',
      dataIndex: 'name',
      render: (value: string, record) => (
        <div style={labelRow1Style}>
          <RouterLink to={`${config[category].routePath}/${record.id}`}>{value}</RouterLink>
        </div>
      )
    },
    {
      key: 'action',
      align: 'right',
      render: (value: string, record) =>
        canModify && (
          <Link size="small" onClick={() => handleEditWorkflow(record)}>
            edit
          </Link>
        )
    }
  ]

  return (
    <div className="workflows">
      <DocumentTitle title={config[category].documentTitle} />
      <PageHeader
        title={config[category].pageTitle}
        breadcrumb={{ routes }}
        extra={
          canModify && (
            <Button key="add" onClick={handleAddWorkflow}>
              Add workflow
            </Button>
          )
        }
      />
      <div className="workflows__body">
        <Card table>
          <Table rowKey="id" dataSource={workflows} pagination={false} columns={columns} loading={loading} />
        </Card>
      </div>
      {canModify && <MutateWorkflowDrawer {...drawerState} category={category} onClose={handleCloseDrawer} />}
    </div>
  )
}

export const LveApprovalWorkflows: FC = () => <Workflows category="lve_approval" />

export const ClaApprovalWorkflows: FC = () => <Workflows category="cla_approval" />

export const AttApprovalWorkflows: FC = () => <Workflows category="att_approval" />
