import React, { CSSProperties, FC, useCallback, useEffect, useState } from 'react'
import useDeepCompareEffect from 'use-deep-compare-effect'
import { Form, Radio, RadioChangeEvent, Space, Switch } from '~/core-components'
import { Col, EditableCard, EditableCardState, LveKeyValues, Row } from '~/components'
import { dispatch } from '~/stores/store'
import { GoogleCalendarState, IGoogleCalendarSetting } from '~/features/leave/types'
import { updateGoogleCalendarSetting } from '~/features/leave/actions'
import { ActionResult, Errors } from '~/types/store'
import { usePermissionGate } from '~/features/iam'
import { Delimiter, Permission, PermissionAction } from '~/constants'
import { Screen, useViewSchemaByName, ViewCriteria, ViewCriteriaAdvanced } from '~/features/selection'

interface GoogleCalendarSettingProps {
  googleCalendar?: GoogleCalendarState
  onEdit?: () => void
  onSave?: () => void
  onCancel?: () => void
}

export const EMPTY_FORM_DATA: IGoogleCalendarSetting = {
  id: '',
  isAutoSync: true,
  leaveTypes: '',
  isAllEmployees: true,
  viewId: '',
  criteria: []
}

const SCREEN_CODE: Screen = 'google_calendar'
const radioStyle: CSSProperties = { marginTop: 6 }

export const GoogleCalendarSetting: FC<GoogleCalendarSettingProps> = ({ googleCalendar, onEdit, onSave, onCancel }) => {
  const [cardState, setCardState] = useState<EditableCardState>()
  const readOnly = cardState !== 'editing' && cardState !== 'saving'
  const [formData, setFormData] = useState<IGoogleCalendarSetting>(EMPTY_FORM_DATA)
  const [errors, setErrors] = useState<Errors>()
  const canModify = usePermissionGate(Permission.googleCalendar, PermissionAction.Modify)

  const viewName = formData?.id ? `${SCREEN_CODE}_${formData.id}` : undefined
  const [view] = useViewSchemaByName(SCREEN_CODE, viewName, false)

  useDeepCompareEffect(() => {
    if (googleCalendar) {
      const { viewId } = googleCalendar

      setFormData({
        ...googleCalendar,
        isAllEmployees: !viewId ? true : false,
        viewId: view?.id,
        criteria: view?.criteria || []
      })
    } else {
      setFormData({ ...EMPTY_FORM_DATA })
    }
  }, [googleCalendar || {}, view || {}])

  const handleFormDataChange = useCallback((updates: { [field: string]: any }) => {
    setErrors(undefined)
    setFormData(data => ({ ...data, ...updates }))
  }, [])

  const handleEdit = useCallback(() => {
    setCardState('editing')
    typeof onEdit === 'function' && onEdit()
  }, [onEdit])

  const handleSave = useCallback(async () => {
    if (googleCalendar) {
      setCardState('saving')
      setErrors(undefined)

      typeof onSave === 'function' && onSave()

      let result: ActionResult | undefined
      try {
        result = await dispatch(updateGoogleCalendarSetting(SCREEN_CODE, googleCalendar.id, formData))
      } catch {
        setCardState('editing')
      }

      if (result?.errors) {
        setCardState('editing')
        setErrors(result.errors)
      }

      if (!result?.errors) {
        setCardState(undefined)
      }
    }
  }, [googleCalendar, onSave, formData])

  const handleCancel = useCallback(() => {
    typeof onCancel === 'function' && onCancel()
    setCardState(undefined)
    setErrors(undefined)

    if (googleCalendar) {
      const { id, isAutoSync, leaveTypes, viewId } = googleCalendar

      setFormData({
        id,
        isAutoSync,
        isAllEmployees: !viewId ? true : false,
        leaveTypes,
        viewId: view?.id,
        criteria: view?.criteria || []
      })
    }
  }, [googleCalendar, onCancel, view])

  useEffect(() => {
    if (googleCalendar?.id) handleCancel()
  }, [googleCalendar?.id, handleCancel])

  return (
    <EditableCard
      className="google-calendar__form"
      title="Setting"
      state={canModify ? cardState : 'readonly'}
      formId="form-google-calendar-setting"
      formLayout="horizontal"
      formLabelCol={{ flex: '130px' }}
      onEdit={handleEdit}
      onSave={handleSave}
      onCancel={handleCancel}
    >
      <Row>
        <Col span={18}>
          <Form.Item label="Auto-sync" validateStatus={errors?.isAutoSync ? 'error' : ''} help={errors?.isAutoSync}>
            <Space>
              <Switch
                checkedChildren="Yes"
                unCheckedChildren="No"
                checked={formData.isAutoSync}
                onChange={(isAutoSync: boolean) => handleFormDataChange({ isAutoSync })}
                disabled={readOnly}
              />
            </Space>
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col span={18}>
          <Form.Item label="Leave types">
            <LveKeyValues
              id="leaveType"
              mode="multiple"
              value={formData.leaveTypes ? formData.leaveTypes.split(Delimiter.pipe) : undefined}
              placeholder="All leave types"
              onChange={(value: string[]) => handleFormDataChange({ leaveTypes: value.join(Delimiter.pipe) })}
              readOnly={readOnly}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col span={18}>
          <Form.Item label="Employee">
            <Radio.Group
              value={formData.isAllEmployees}
              onChange={(event: RadioChangeEvent) => handleFormDataChange({ isAllEmployees: event.target.value })}
              readOnly={readOnly}
              style={radioStyle}
            >
              <Space direction="vertical">
                <Radio value={true}>All employees</Radio>
                <Radio value={false}>Specific employees</Radio>
              </Space>
            </Radio.Group>
          </Form.Item>
        </Col>
        <Col span={24} hidden={formData.isAllEmployees}>
          <Form.Item label=" " colon={false} style={{ marginLeft: 25 }}>
            <ViewCriteriaAdvanced
              label=""
              screenCode={SCREEN_CODE}
              viewId={formData.viewId}
              onChange={(criteria: ViewCriteria[]) => handleFormDataChange({ criteria })}
              readOnly={readOnly}
            />
          </Form.Item>
        </Col>
      </Row>
    </EditableCard>
  )
}
