import { FC, useCallback, useEffect, useState } from 'react'
import { Form } from '~/core-components'
import { Col, EditableCard, EditableCardState, Row, SysOptions } from '~/components'
import { usePermissionGate } from '~/features/iam'
import { SelectWorkflow } from '~/features/workflow'
import { ActionResult, Errors } from '~/types/store'
import { dispatch } from '~/stores/store'
import { AttDailyApproval, Permission, PermissionAction } from '~/constants'
import { AttendanceGroupDailyRecordState, AttendanceGroupState } from '../../../types'
import { updateAttendanceGroup } from '../../../actions'

interface AttendanceGroupDailyRecordProps {
  attendanceGroup?: AttendanceGroupState
  onEdit?: () => void
  onSave?: () => void
  onCancel?: () => void
}

const EMPTY_FORM_DATA: AttendanceGroupDailyRecordState = {
  dailyRecordApproval: AttDailyApproval.ReportingManager,
  dailyRecordWorkflowId: ''
}

export const AttendanceGroupDailyRecord: FC<AttendanceGroupDailyRecordProps> = ({
  attendanceGroup,
  onEdit,
  onSave,
  onCancel
}) => {
  const [cardState, setCardState] = useState<EditableCardState>()
  const [formData, setFormData] = useState<AttendanceGroupDailyRecordState>(EMPTY_FORM_DATA)
  const [errors, setErrors] = useState<Errors>()
  const canModify = usePermissionGate(Permission.attendanceGroup, PermissionAction.Modify)

  useEffect(() => {
    if (attendanceGroup) {
      const { dailyRecordApproval, dailyRecordWorkflowId } = attendanceGroup
      setFormData({
        dailyRecordApproval,
        dailyRecordWorkflowId
      })
    } else {
      setFormData(EMPTY_FORM_DATA)
    }
  }, [attendanceGroup])

  const handleEdit = useCallback(() => {
    setCardState('editing')
    typeof onEdit === 'function' && onEdit()
  }, [onEdit])

  const handleSave = useCallback(async () => {
    if (attendanceGroup) {
      setCardState('saving')
      setErrors(undefined)

      typeof onSave === 'function' && onSave()

      let result: ActionResult | undefined
      try {
        result = await dispatch(updateAttendanceGroup(attendanceGroup.id, attendanceGroup, formData))
      } catch {
        setCardState('editing')
      }

      if (result?.errors) {
        setCardState('editing')
        setErrors(result.errors)
      }

      if (!result?.errors) {
        setCardState(undefined)
      }
    }
  }, [attendanceGroup, formData, onSave])

  const handleCancel = useCallback(() => {
    typeof onCancel === 'function' && onCancel()
    setCardState(undefined)
    setErrors(undefined)

    if (attendanceGroup) {
      const { dailyRecordApproval, dailyRecordWorkflowId } = attendanceGroup
      setFormData({
        dailyRecordApproval,
        dailyRecordWorkflowId
      })
    }
  }, [attendanceGroup, onCancel])

  const handleFormDataChange = useCallback((updates: { [field: string]: any }) => {
    setFormData(data => ({ ...data, ...updates }))
  }, [])

  const readOnly = cardState !== 'editing' && cardState !== 'saving'

  return (
    <EditableCard
      title="Daily record setting"
      bodyStyle={{ paddingBottom: attendanceGroup ? 6 : 24, paddingTop: 6 }}
      state={!canModify ? 'readonly' : cardState}
      formId="form-attendance-group-daily-record"
      onEdit={handleEdit}
      onSave={handleSave}
      onCancel={handleCancel}
    >
      <Row gutter={30}>
        <Col span={12}>
          <Form.Item
            label="Approval"
            validateStatus={errors?.dailyRecordApproval ? 'error' : ''}
            help={errors?.dailyRecordApproval}
          >
            <SysOptions
              type="att_daily_approval"
              readOnly={readOnly}
              value={formData.dailyRecordApproval}
              onChange={(dailyRecordApproval: string) => handleFormDataChange({ dailyRecordApproval })}
            />
          </Form.Item>
        </Col>
        <Col span={12} hidden={formData.dailyRecordApproval !== AttDailyApproval.Workflow}>
          <Form.Item
            label="Workflow"
            validateStatus={errors?.dailyRecordWorkflowId ? 'error' : ''}
            help={errors?.dailyRecordWorkflowId}
          >
            <SelectWorkflow
              category="att_approval"
              readOnly={readOnly}
              value={formData.dailyRecordWorkflowId}
              onChange={(dailyRecordWorkflowId: string) => handleFormDataChange({ dailyRecordWorkflowId })}
            />
          </Form.Item>
        </Col>
      </Row>
    </EditableCard>
  )
}
