import React, { CSSProperties, FC, KeyboardEvent, useCallback } from 'react'
import { motion, AnimatePresence } from 'framer-motion'
import {
  Button,
  ButtonProps,
  Card,
  CardProps,
  Form,
  FormProps,
  Link,
  SecondaryText,
  Space,
  Tag
} from '../../core-components'
import { Row } from '../Row/Row'
import { Col } from '../Col/Col'
import { ColProps } from 'antd'
import { FormLayout } from 'antd/lib/form/Form'

export type EditableCardState = 'editing' | 'empty' | 'saving' | 'readonly' | undefined

interface EditableCardProps extends CardProps {
  title?: React.ReactNode
  bodyStyle?: CSSProperties
  state: EditableCardState
  formId?: string
  formLayout?: FormLayout
  formLabelCol?: ColProps
  onEdit?: () => void
  onSave?: () => void
  onCancel?: () => void
  extraButtons?: React.ReactNode[]
}

const variants = {
  open: { opacity: 1, height: 54 },
  closed: { opacity: 0, height: 0 }
}

const motionDivStyle: CSSProperties = { overflowY: 'hidden' }

export const EditableCard: FC<EditableCardProps> = ({
  state,
  children,
  formId,
  formLayout = 'vertical',
  formLabelCol,
  onEdit,
  onSave,
  onCancel,
  loading,
  extra,
  extraButtons,
  ...cardProps
}: EditableCardProps) => {
  const handleEdit = useCallback(() => {
    if (state === 'empty' || state === 'readonly') return
    typeof onEdit === 'function' && onEdit()
  }, [onEdit, state])

  const handleSave = useCallback(() => {
    if (state === 'empty' || state === 'readonly') return
    typeof onSave === 'function' && onSave()
  }, [onSave, state])

  const handleCancel = useCallback(() => {
    typeof onCancel === 'function' && onCancel()
  }, [onCancel])

  const handleKeyDown = useCallback(
    (event: KeyboardEvent<HTMLDivElement>) => {
      if (event.key === 'Escape') {
        typeof onCancel === 'function' && onCancel()
      }
    },
    [onCancel]
  )

  const handleDoubleClick = useCallback(() => {
    if (state === 'empty' || state === 'readonly' || loading) return
    typeof handleEdit === 'function' && handleEdit()
  }, [handleEdit, state, loading])

  const empty = state === 'empty'
  const readonly = state === 'readonly'
  const editing = state === 'editing'
  const saving = state === 'saving'

  const EditButton = <Link onClick={handleEdit}>edit</Link>
  const CancelButton = <Link onClick={handleCancel}>cancel</Link>
  const editExtra = empty ? null : readonly ? <Tag>read-only</Tag> : editing ? CancelButton : EditButton
  const submitProps: Partial<ButtonProps> = formId ? { form: formId, htmlType: 'submit' } : { onClick: handleSave }
  const formProps: Partial<FormProps> = formId
    ? { id: formId, layout: formLayout, labelCol: formLabelCol, onFinish: handleSave }
    : {}

  return (
    <Card
      extra={
        <Space>
          {extra}
          {editExtra}
        </Space>
      }
      loading={loading}
      {...cardProps}
      onKeyDown={handleKeyDown}
      onDoubleClick={handleDoubleClick}
    >
      <Form {...formProps}>
        {empty && <SecondaryText>No data available</SecondaryText>}
        {!empty && children}
        <Row>
          <Col span={24}>
            <AnimatePresence initial={false}>
              {(editing || saving) && (
                <motion.div
                  style={motionDivStyle}
                  variants={variants}
                  initial="closed"
                  animate="open"
                  exit="closed"
                  transition={{ duration: 0.2 }}
                >
                  <Button.ActionGroup>
                    {extraButtons}
                    <Button type="primary" loading={saving} {...submitProps}>
                      Save
                    </Button>
                  </Button.ActionGroup>
                </motion.div>
              )}
            </AnimatePresence>
          </Col>
        </Row>
      </Form>
    </Card>
  )
}
