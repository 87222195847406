import {
  removeGoogleCalendarConnection,
  setGoogleCalendarConnection,
  setGoogleCalendarConnectionsLoading
} from '../reducers'
import { apiCheckGoogleCalendarConnection } from '../api/google-calendar.api'
import { ThunkResult } from '~/types/store'
import { DEFAULT_FETCH_OPTIONS, FetchOptions } from '~/types/common'

export const fetchGoogleCalendarConnection = (
  id: string,
  options: FetchOptions = DEFAULT_FETCH_OPTIONS
): ThunkResult<void> => {
  return async (dispatch, getState) => {
    if (!id) return

    if (options.strategy === 'when-empty') {
      const hasData = getState().leave.googleCalendarConnections?.entities[id]
      if (hasData) return
    }

    try {
      dispatch(setGoogleCalendarConnectionsLoading(true))
      const { result, status } = await apiCheckGoogleCalendarConnection(id)
      if (status) {
        dispatch(setGoogleCalendarConnection(result))
      } else {
        dispatch(removeGoogleCalendarConnection(id))
      }
    } finally {
      dispatch(setGoogleCalendarConnectionsLoading(false))
    }
  }
}
