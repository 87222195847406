import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { dispatch } from '~/stores/store'
import { StoreState } from '~/types/store'
import { FetchStrategy } from '~/types/common'
import { fetchGoogleCalendar } from '../actions'
import { GoogleCalendarState } from '../types'

export const useGoogleCalendar = (
  id: string,
  fetchStrategy: FetchStrategy = 'always'
): [GoogleCalendarState | undefined, boolean] => {
  const data = useSelector((state: StoreState) => state.leave.googleCalendars?.entities[id])
  const loading = useSelector((state: StoreState) => state.leave.googleCalendarsLoading)

  useEffect(() => {
    dispatch(fetchGoogleCalendar(id, { strategy: fetchStrategy }))
  }, [id, fetchStrategy])

  return [data, loading]
}
