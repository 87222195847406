import { ThunkResult } from '~/types/store'
import { apiGetGoogleCalendarAuthUrl } from '../api/google-calendar.api'
import { setGoogleCalendarRedirectUrl } from '../reducers'
import { showError } from '~/utils'

export const initiateGoogleCalendarAuthentication = (id?: string): ThunkResult<void> => {
  return async dispatch => {
    const { result } = await apiGetGoogleCalendarAuthUrl(id || '')

    if (result) {
      dispatch(setGoogleCalendarRedirectUrl(window.location.href))
      window.location.href = result
    } else {
      showError('Failed to get Google Calendar authentication URL')
    }
  }
}
