export enum AttTimeLogApproval {
  ReportingManager = 'mgr'
}

export enum AttDailyApproval {
  ReportingManager = 'mgr',
  Workflow = 'wf',
  NotRequired = 'x'
}

export enum AttendanceGroupType {
  Monthly = 'm',
  Weekly = 'w',
  ReportingOnly = 'r'
}

export enum AttendancePeriodStatus {
  Draft = 'd',
  Completed = 'c'
}

export class AttScheduleType {
  static get flexi() {
    return 'flexi'
  }
  static get fixed() {
    return 'fixed'
  }
}

export class AttShiftDayCode {
  static get mon() {
    return 'mon'
  }
  static get tue() {
    return 'tue'
  }
  static get wed() {
    return 'wed'
  }
  static get thu() {
    return 'thu'
  }
  static get fri() {
    return 'fri'
  }
  static get sat() {
    return 'sat'
  }
  static get sun() {
    return 'sun'
  }
  static get off() {
    return 'off'
  }
  static get rest() {
    return 'rest'
  }
  static get holiday() {
    return 'hol'
  }
}

export const NON_WORKING_DAY_CODES = [AttShiftDayCode.holiday, AttShiftDayCode.off, AttShiftDayCode.rest]

export enum TimeLogImportType {
  Pair = 'p',
  Single = 's'
}

export enum TimeLogImportDelimiter {
  Comma = 'c',
  Tab = 't'
}

export enum TimeLogStatus {
  Pending = 'p',
  Approved = 'a',
  Rejected = 'r'
}
