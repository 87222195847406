import omit from 'lodash/omit'
import { showSuccess, showError } from '~/utils'
import { ThunkResult } from '~/types/store'
import { apiUpdateGoogleCalendarSetting } from '../api/google-calendar.api'
import { fetchViewSchema, removeView, removeViewSchema, Screen } from '~/features/selection'
import { IGoogleCalendarSetting } from '../types'
import { setGoogleCalendar } from '../reducers'

export const updateGoogleCalendarSetting = (
  screenCode: Screen,
  id: string,
  request: IGoogleCalendarSetting
): ThunkResult<void> => {
  return async dispatch => {
    const { status, result, errors, message, errorData } = await apiUpdateGoogleCalendarSetting(
      id,
      omit(request, 'viewId')
    )

    if (status) {
      dispatch(setGoogleCalendar(result))

      if (result.viewId) {
        dispatch(fetchViewSchema(screenCode, result.viewId))
      } else {
        if (request.viewId) {
          dispatch(removeViewSchema({ screenCode, id: request.viewId }))
          dispatch(removeView({ screenCode, viewId: request.viewId }))
        }
      }

      showSuccess('Saved')
    } else {
      if (Object.keys(errors).length === 0) {
        showError(message, errorData)
      }
    }
    return { errors }
  }
}
