import React, { FC, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { ZealysLoading } from '~/components'
import { dispatch } from '~/stores/store'
import { useQuery } from '~/hooks/use-query'
import { showError, showSuccess } from '~/utils'
import { SETTINGS_ROUTES } from '~/routes/routes'
import { StoreState } from '~/types/store'
import { apiGetGoogleCalendarAuthToken } from '../../api/google-calendar.api'
import { setGoogleCalendarState } from '../../reducers'

interface GoogleCalendarCallbackProps {}

export const GoogleCalendarCallback: FC<GoogleCalendarCallbackProps> = () => {
  const query = useQuery()
  const history = useHistory()
  const code = query.get('code')
  const state = query.get('state')
  const error = query.get('error')
  const redirectUrl = useSelector((state: StoreState) => state.leave.googleCalendarRedirectUrl)

  useEffect(() => {
    let id: string
    const retrieveGoogleCalendarToken = async () => {
      if (code && state) {
        await dispatch(setGoogleCalendarState(state))
        const { result, status, message, errors, errorData } = await apiGetGoogleCalendarAuthToken(code, state)
        id = result?.id

        if (!status || !result) {
          if (Object.keys(errors).length === 0) {
            showError(message || 'Error occurred when getting access token from Google Calendar.')
          } else {
            const errorDescription = Object.entries(errors).map(([field, messages]) =>
              messages.map((message, index) => <div key={index}>{message}</div>)
            )

            showError('Error(s)', errorData, errorDescription)
          }
        } else {
          showSuccess('Connected')
        }
      } else {
        if (error === 'access_denied') {
          showError('Unable to connect. Did you cancel?')
        } else {
          showError(`Unable to connect. Error: ${error}`)
        }
      }

      if (redirectUrl) {
        window.location.href = redirectUrl
      } else {
        if (id) history.push(SETTINGS_ROUTES.googleCalendar.replace(':id', id))
        else history.push(SETTINGS_ROUTES.googleCalendars)
      }
    }

    retrieveGoogleCalendarToken()
  }, [code, state, error, history, redirectUrl])

  return <ZealysLoading />
}
