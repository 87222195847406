import React, { FC, useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useHistory, useParams, useRouteMatch } from 'react-router-dom'
import confirm from 'antd/lib/modal/confirm'
import { Button, PageHeader, Tabs } from '~/core-components'
import { DocumentTitle } from '~/components'
import { attendanceMenus, useMasterMenu } from '~/features/master'
import { usePermissionGate } from '~/features/iam'
import { SETTINGS_ROUTES } from '~/routes/routes'
import { Permission, PermissionAction } from '~/constants'
import { MenuItem } from '~/types/common'
import { dispatch } from '~/stores/store'
import { selectAttendanceGroups } from '../../selectors'
import { useAttendanceGroup } from '../../hooks'
import { deleteAttendanceGroup } from '../../actions'
import { AttendanceGroupInfo } from './components/AttendanceGroupInfo'
import { AttendanceGroupTimeLog } from './components/AttendanceGroupTimeLog'
import { AttendanceGroupDailyRecord } from './components/AttendanceGroupDailyRecord'
import { AddAttendanceGroupDrawer } from './components/AddAttendanceGroupDrawer'
import './AttendanceGroup.less'

interface AttendanceGroupProps {}

interface AttendanceGroupParams {
  id: string
}

interface DrawerState {
  visible: boolean
}

const DEFAULT_DRAWER_STATE: DrawerState = { visible: false }

const routes = [
  {
    path: SETTINGS_ROUTES.main,
    breadcrumbName: 'Settings'
  },
  {
    path: SETTINGS_ROUTES.attendanceGroups,
    breadcrumbName: 'Overview'
  },
  {
    path: '',
    breadcrumbName: 'Attendance group'
  }
]

export const AttendanceGroup: FC<AttendanceGroupProps> = () => {
  const { id } = useParams<AttendanceGroupParams>()
  const attendanceGroups = useSelector(selectAttendanceGroups)
  const [attendanceGroup] = useAttendanceGroup(id)
  const [editing, setEditing] = useState(false)
  const match = useRouteMatch<{ tab: string }>()
  const tab = match.params.tab
  const history = useHistory()
  const canModify = usePermissionGate(Permission.attendanceGroup, PermissionAction.Modify)
  const [drawerState, setDrawerState] = useState<DrawerState>(DEFAULT_DRAWER_STATE)
  const { setMenus } = useMasterMenu()

  const handleAddAttendanceGroup = useCallback(() => {
    setDrawerState({ visible: true })
  }, [])

  const handleCloseDrawer = useCallback(() => {
    setDrawerState(DEFAULT_DRAWER_STATE)
  }, [])

  useEffect(() => {
    const menus: MenuItem[] = attendanceGroups.map(s => ({
      path: SETTINGS_ROUTES.attendanceGroup.replace(':id', s!.id).replace(':tab?', tab || 'general'),
      value: s!.name,
      sysPermissionId: Permission.attendanceGroup
    }))

    if (canModify)
      menus.push({
        path: (
          <Button type="dashed" onClick={handleAddAttendanceGroup} block>
            Add attendance group
          </Button>
        ),
        value: '',
        sysPermissionId: ''
      })

    setMenus(menus)
    return () => {
      setMenus(attendanceMenus)
    }
  }, [setMenus, attendanceGroups, tab, canModify, handleAddAttendanceGroup])

  const handleEdit = useCallback(() => setEditing(true), [])
  const handleView = useCallback(() => setEditing(false), [])

  const handleDelete = useCallback(() => {
    if (id && attendanceGroup) {
      confirm({
        title: 'Delete attendance group',
        content: `Do you want to delete attendance group "${attendanceGroup?.name}"?`,
        onOk: () => {
          dispatch(deleteAttendanceGroup(id))
          history.push(SETTINGS_ROUTES.attendanceGroups)
        },
        okText: 'Delete',
        okType: 'danger'
      })
    }
  }, [id, attendanceGroup, history])

  const handleTabChange = useCallback(
    (activeKey: string) => {
      history.push(`${SETTINGS_ROUTES.attendanceGroup.replace(':id', id).replace(':tab?', activeKey)}`)
    },
    [id, history]
  )

  return (
    <div id="attendance-group" className="attendance-group">
      <DocumentTitle title="Attendance Group" />
      <PageHeader
        title="Attendance group"
        containerId="attendance-group"
        breadcrumb={{ routes }}
        extra={
          canModify &&
          editing && (
            <Button key="add" onClick={handleDelete}>
              Delete
            </Button>
          )
        }
      />
      <div className="attendance-group__body">
        <Tabs
          defaultActiveKey={tab || 'general'}
          onChange={handleTabChange}
          items={[
            {
              key: 'general',
              label: 'General',
              children: (
                <>
                  <AttendanceGroupInfo
                    attendanceGroup={attendanceGroup}
                    onEdit={handleEdit}
                    onSave={handleView}
                    onCancel={handleView}
                  />
                  <AttendanceGroupTimeLog
                    attendanceGroup={attendanceGroup}
                    onEdit={handleEdit}
                    onSave={handleView}
                    onCancel={handleView}
                  />
                  <AttendanceGroupDailyRecord
                    attendanceGroup={attendanceGroup}
                    onEdit={handleEdit}
                    onSave={handleView}
                    onCancel={handleView}
                  />
                </>
              )
            }
          ]}
        />
        {canModify && <AddAttendanceGroupDrawer {...drawerState} onClose={handleCloseDrawer} />}
      </div>
    </div>
  )
}
